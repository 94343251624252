const langListRef = document.querySelector('.lang__list');

langListRef.addEventListener('click', onLangBtnClick);

function onLangBtnClick(e) {
  switch (e?.target?.dataset?.lang) {
    case "ru":
      const currentPathname = window?.location?.pathname;
      window.location.href = currentPathname?.slice(3, currentPathname?.length)
      break;

    case "en":
      window.location.href = `/en${window?.location?.pathname}`
      break;
  
    default:
      window.location.href = '/'
      break;
  }
}
